import React from 'react'
import './slider.css'

const Slider = (props) => {

    const pictures = props.data.pictures

    // const log = () => {
    //     console.log("HALLO MAGL :", props.data.pictures)
    // }

    // log()

    return (
        <div className="containerSlider">

            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner" role="listbox">
                    {pictures && pictures.map((picture, id) => (
                        <div key={id} className={id === 0 ? "carousel-item active" : "carousel-item"}>
                            <img src={picture} className="d-block h-100 w-100 imgItem" alt="..." />
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev buttonPrevious" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next buttonNext" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>

    )
}

export default Slider
