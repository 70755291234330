import React from 'react'
import { NavLink } from 'react-router-dom'
import './navbar.css'

const Navbar = () => {

    const projets = "projets"
    const bio = "à propos"
    const contact = "contact"

    return (
        <>
            <nav>
                <ul className="navList">
                    <li className="navItem"><NavLink activeClassName="active" className="navlink" to="/home">{projets.toUpperCase()}</NavLink></li>
                    <li className="navItem"><NavLink activeClassName="active" className="navlink" to="/biography">{bio.toUpperCase()}</NavLink></li>
                    <li className="navItem"><NavLink activeClassName="active" className="navlink" to="/contact">{contact.toUpperCase()}</NavLink></li>
                </ul>
            </nav>
        </>
    )
}

export default Navbar
