// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwbeuEZCj3OoPssR5DVod6mkOvVNVNvxQ",
  authDomain: "chaille.firebaseapp.com",
  projectId: "chaille",
  storageBucket: "chaille.appspot.com",
  messagingSenderId: "686316153271",
  appId: "1:686316153271:web:947fb6c63120a620e3e032",
  measurementId: "G-G4JFCM4WHF"
};

// Initialize Firebase
export const fb = initializeApp(firebaseConfig);
export const db = getFirestore(fb)
const analytics = getAnalytics(fb);