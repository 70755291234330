import React from 'react'
import './projectAppBar.css'

const ProjectAppBar = (props) => {

    const descriptionProject = props.data

    return (
        <div className="appBarContainer">
            <p>{descriptionProject}</p>
        </div>
    )
}

export default ProjectAppBar
