import { logRoles } from '@testing-library/react'
import React from 'react'
import './appBar.css'

const AppBar = (props) => {

    const isMobile = props.isMobile;
    const isBioPage = props?.biography;
    const authorName = "Jérémy Chaillou"

    return (
        <div className="appBarContainer">
            {
                (!isMobile) &&
                <p>STUDIO DE CRÉATION PLURIDISCIPLINAIRE ET TRANSVERSAL.</p>

            }
            {
                (isBioPage && isMobile) &&
                <p>{authorName.toUpperCase()}</p>
            }
        </div>
    )
}

export default AppBar
