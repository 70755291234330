import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import './header.css'

const Header = () => {
    return (
        <div className="headerContainer">
            <div className="innerHeaderContainer">
                <Link to="/home"><img src="https://zupimages.net/up/21/18/ann9.png" alt="logo" /></Link>
                <Navbar />
            </div>
        </div>
    )
}

export default Header
