import React from 'react'
import { useMediaQuery } from 'react-responsive';
import AppBar from '../../components/AppBar/AppBar';
import './Contact.css'

const Contact = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <>
            <div className="contactContainer">

                <div className="contentContactContainer">
                    <div className="contentContactInnerContainer">
                        <p className="subtitle">EN LIGNE</p>
                        <a className="link" href="mailto:chaille@chaille.fr">chaille@chaille.fr</a>
                        <a className="link" href="https://chaille.web.app/">www.chaille.fr</a>
                        <a className="link" target="_blank" href="https://www.instagram.com/chaille__">instagram</a>
                    </div>

                    <div className="contentContactInnerContainer">
                        <p className="subtitle">COURRIER</p>
                        <p className="content">22 cours Pierre Puget <br /> 13006, Marseille</p>
                    </div>
                </div>

            </div>
            <AppBar />
        </>
    )
}

export default Contact
