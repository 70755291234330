import React from 'react'
import AppBar from '../../components/AppBar/AppBar'
import { useMediaQuery } from 'react-responsive';

import './Biography.css'

const Biography = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return (
        <>
            <div className="biographyContainer">

                {!isMobile &&
                    <div className="titleContainer">
                        <h1 className="titleBiography">JÉRÉMY <br /> CHAILLOU</h1>
                    </div>
                }

                <div className='containerPicture'>
                    <img className="picture" src="https://firebasestorage.googleapis.com/v0/b/chaille.appspot.com/o/Assets%2Fphoto-profil-j%C3%A9r%C3%A9my-chaillou.webp?alt=media&token=e0ad4ca2-48f5-4c15-8f29-6e4a88333928" alt="Jérémy Chaillou" />
                </div>

                <div className="contentContainer">

                    <p className="quoteText">
                        <b>
                            « Sur un terrain de jeu inépuisable,
                            l’espace et l’objet comme moyen
                            d’expression. »
                        </b>
                    </p>

                    <p className="text">
                        Septembre 2016 - Tout juste diplômé de l’école
                        Camondo, Jérémy Chaillou retrouve sa ville de
                        coeur Marseille;
                    </p>

                    <p className="text">
                        Il y créé un studio d’architecture pluridisciplinaire et
                        transversal. Par sa sensibilité, il aﬃrme son
                        positionnement dans un triptyque où architecture,
                        art et design ne font qu’un. Dans un climat propice
                        aux mutations, ce studio tend à explorer les
                        frontières de la création et à déﬁnir une vision
                        contemporaine de l’architecture intérieure et du
                        design.
                    </p>

                    <p className="text">
                        De l’horizon du Bassin Méditerranéen à
                        l’eﬀervescence Parisienne, les styles
                        s’entrechoquent et expriment deux façons d’habiter
                        l’espace.
                    </p>

                    <p className="text">
                        L’agence esquisse sa propre vision hors du cadre et
                        des codes prédéﬁnis et marque les esprits par un
                        style précis et singulier.
                    </p>

                    <p className="text last">
                        Entre géométrie, rythme et lumière il trouve sa
                        propre harmonie, le lieu comme matière, le dessin
                        comme vision. La richesse des rencontres, la dualité
                        entre ville et nature, entre terre et mer, entre ombre
                        et lumière, inspire son univers.
                    </p>
                </div>
            </div>
            {/* {!isMobile && */}
            <AppBar biography={true} isMobile={isMobile}/>
            {/* } */}
        </>
    )
}

export default Biography
