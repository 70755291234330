import { Route, Switch } from "react-router-dom";
import Home from './views/Home/Home';
import Biography from './views/Biography/Biography';
import Contact from './views/Contact/Contact'
import './App.css';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Project from "./views/Project/Project";

function App() {
  return (
    <div className="App">
      <div className='header'>
        <Header />
      </div>
      <div className="main">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/biography" component={Biography} />
          <Route path="/contact" component={Contact} />
          <Route path="/project/:path" component={Project} />
        </Switch>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
