import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <div className="footerContainer">
            <div className="innerFooterContainer">
                <div className="contentFooter">
                    <p> © STUDIO CHAILLE — 2022</p>
                    <a className="contentFooter" href="mailto:chaille@chaille.fr">CHAILLE@CHAILLE.FR</a>
                </div>

                <ul className="socialList">
                    <li className="socialItem"><a className="linkItem" target="_blank" href="https://www.instagram.com/chaille__">INSTAGRAM</a></li>
                </ul>
            </div>
        </div>
    )
}

export default Footer
