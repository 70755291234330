import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import './home.css'
import AppBar from '../../components/AppBar/AppBar';
import { linkProjects } from '../../data/data'
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config"


const Home = () => {
    const [image, setImage] = useState(null);
    const [isFetch, setIsFetch] = useState(false);
    const [dataProjects, setDataProjects] = useState([]);
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const resetBackground = () => {
        setImage(null)
    }

    useEffect(() => {
        const getProjects = async () => {
            let result = []
            const querySnapshot = await getDocs(collection(db, "pictures"));
            querySnapshot.forEach((doc) => {
                result.push(doc.data())

            });
            setDataProjects(result);
            setIsFetch(true);
        }
        getProjects();
    }, [])

    return (
        <>
            <div className="homeContainer">
                <div className="innerHomeContainer">
                    <div className="items">
                        {dataProjects &&
                            dataProjects.sort((a, b) => b.id - a.id).map(project => (
                                <Link key={project.id} className="item" onMouseEnter={() => setImage(project.thumbmail)} onMouseLeave={() => resetBackground()} to={'project/' + project.path}>{project.name}</Link>
                            ))}
                    </div>
                    {!isMobile &&
                        <div className="containerBackground">
                            {image &&
                                <img src={image} alt="picture" />
                            }

                        </div>
                    }
                </div>
            </div>
            <AppBar />
        </>

    )
}

export default Home
