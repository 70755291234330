import React, { useState, useEffect } from 'react'
import Slider from '../../components/Carousel/Slider'
import { useMediaQuery } from 'react-responsive';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config"
import SliderMobile from '../../components/Carousel/SliderMobile';
import './project.css'
import ProjectAppBar from '../../components/ProjectAppBar/ProjectAppBar';


const Project = (props) => {
    const path = props.match.params.path
    const [dataProjects, setDataProjects] = useState([]);
    const [isFetch, setIsFetch] = useState(false);
    const [project, setProject] = useState('')
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    let projo

    useEffect(() => {
        const getProjects = async () => {
            let result = []
            const querySnapshot = await getDocs(collection(db, "pictures"));
            querySnapshot.forEach((doc) => {
                result.push(doc.data())
            });
            setDataProjects(result);
            setIsFetch(true);
        }
        getProjects();
    }, [])


    const mapData = () => {
        dataProjects.map(project => {
            if (path === project.path) {
                projo = project
            }
        })
    }

    mapData()

    return (
        <>
            {
                projo &&
                <div className='container-project'>
                    {!isMobile ?
                        projo &&
                        <Slider data={projo} />
                        :
                        <SliderMobile data={projo} />
                    }
                    <ProjectAppBar data={projo.description}/>
                </div>
            }
        </>
    )
}

export default Project
