import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import "swiper/css";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import './sliderMobile.css'


const SliderMobile = (props) => {

    const project = props.data

    return (
        <>
            {project &&
                <Swiper className="mySwiper">
                    {project.pictures && project.pictures.map((picture) => (
                        <SwiperSlide>
                            <div className='inner-swiper-slide'><img src={picture} alt={project.name} /></div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            }
        </>
    );
};

export default SliderMobile;
